// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, /* IconButton, InputBase */ } from '@mui/material';
// import Search from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
// import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER } from '../../../config-global';
// components
import Logo from '../../../components/logo';
// import Iconify from '../../../components/iconify';
// import { useSettingsContext } from '../../../components/settings';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import ChipsSearch from '../chipssearch/ChipsSearch'

// ----------------------------------------------------------------------


// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     // @ts-ignore
//     primary: {
//       main: '#1976d2',
//     },
//   },
// });

export default function Header() {
  const theme = useTheme();

  // const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const renderContent = (
    <>
      {isDesktop && <Logo 
        color='grey'
         sx={{ mr: 2 }} />}

      <Typography
              variant="h6"
              noWrap
              component="div"
              color='grey'
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              FEAST YOUR EYES
      </Typography>

      <ChipsSearch />

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}
        {/* <ContactsPopover /> */}
        <NotificationsPopover />
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
