// import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
// import useResponsive from '../../hooks/useResponsive';
// components
// import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
// import Footer from './Footer';
import Header from './header/Header';

// ----------------------------------------------------------------------

export default function FyeLayout() {
  // const { themeLayout } = useSettingsContext();

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
        <Header />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >

          <Main>
            <Outlet />
          </Main>

        </Box>
                {/* <footer /> */}
      </Box>
  );
}
