import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { EntityState } from '@reduxjs/toolkit'
import {
  Venue,
  venuesAdapter,
} from './venuesSlice'
import { HOST_API_KEY } from '../../../../config-global';
// const debug = false

export const venuesApi = createApi({
  reducerPath: 'venuesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${HOST_API_KEY}/api/v1`
    // prepareHeaders(headers) {
    //    headers.set('x-api-key', DOGS_API_KEY);
    //
    //   return headers;
    // },
  }),
  tagTypes: ['Venue'],
  endpoints: (builder) => ({
    fetchVenuesByIds: builder.query<EntityState<Venue>, (string | number)[]>({
      query: (args) => (`/venue?ids=[${args.map((id: (string | number)) => id)}]`
      ),
      providesTags: ['Venue'],
      transformResponse(response: Venue[]) {
        return venuesAdapter.addMany(venuesAdapter.getInitialState(), response)
      },
    }),
  }),
})

export const { useFetchVenuesByIdsQuery } = venuesApi;
