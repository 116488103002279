import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { HOST_API_KEY } from '../../../../config-global';

// import { HOST_API_KEY } from '../../config-global';
// const debug = false

interface CallResponse {
  venueIds: string;
  mediaIds: string;
  // vibe_ids: string;
}

export type TransformedResponse = {
  venue_ids: number[];
  media_ids: number[];
  // vibe_ids: number[];
}

export const mapApi = createApi({
  reducerPath: 'mapApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${HOST_API_KEY}/api/v1`
    // prepareHeaders(headers) {
    //    headers.set('x-api-key', DOGS_API_KEY);
    //
    //   return headers;
    // },
  }),
  refetchOnFocus: false,
  refetchOnReconnect: true,
  // tagTypes: ['Venue'],
  endpoints: (builder) => ({
    getMapObjectIdsByBounds: builder.query<TransformedResponse, number[][]>({
      query: (args) => (`/venueAddress?bounds=true&current=[${args}]`),
      transformResponse: (response: CallResponse) =>({
          venue_ids: response.venueIds.split(",").map(str => ( Number(str) )),
          media_ids: response.mediaIds.split(",").map(str => ( Number(str) )),
          // vibe_ids: response.vibe_ids.split(",").map(str => { return Number(str) }),
      }),
    }),
  }),
})

export const { useGetMapObjectIdsByBoundsQuery } = mapApi;
