// usersSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../api/types';

interface UserState {
  user: User | null;
  token: string | null;
}

const initialState: UserState = {
  user: null,
  token: localStorage.getItem('token'),
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem('token', action.payload.token);
    },
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
    },
  },
});


export const { setUser, logoutUser } = usersSlice.actions;

export default usersSlice.reducer;
