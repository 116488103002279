import { createSlice, createEntityAdapter, PayloadAction, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash'
import type { RootState /* , store, */ } from '../../../../redux/store'

export interface Media {
  id: string;
  venue_id: string;
  name: string;
  alt_text: string;
  description: string;
  mediable_type: string;
  mediable_id: string;
  slug: string;
  business_id: string;
  published: string;
  principle: string;
  raw: string;
  asset: string;
  tile: string;
  filters: string[];
}

export const mediaAdapter = createEntityAdapter<Media>({
  // Assume IDs are stored in a field `venue.id`
  selectId: (object: Media) => object.id,
  // Keep the "all IDs" array sorted based on venue distanst from the user
  sortComparer: (a, b) => (a.id.toString()).localeCompare(b.id.toString(), 'en', { numeric: true })
});

export const mediaSlice = createSlice({
  name: 'media',
  initialState: mediaAdapter.getInitialState({
    mediaCount: 0,
    requestedMediaIds: [],
    availableMediaFilters: [],
    status: 'idle',
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSuccess: (state) => {
      state.status = 'success'
    },
    setError: (state) => {
      state.status = 'error'
    },
    setRequestMediaIds: (state, action: PayloadAction<any>) => {
      state.requestedMediaIds = action.payload;
      // state.availableMediaFilters = _.union(state.entities.map((x: any) => x.filters));
    },
    mediaAdded: mediaAdapter.addOne,
    cacheMediaAfterQuery: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      mediaAdapter.setAll(state, action.payload.entities);
      state.mediaCount = state.ids.length;
    },
    upsertMedia: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      mediaAdapter.upsertMany(state, action.payload.entities);
      state.mediaCount = state.ids.length;
    },
  },
});

// Can create a set of memoized selectors based on the location of this entity state
export const mediaSelector = mediaAdapter.getSelectors<RootState>(
  (state) => state.media
);

// And then use the selectors to retrieve values
export const { selectAll: selectAllMedia } = mediaAdapter.getSelectors<RootState>((state) => state.media);
export const { selectIds: selectMediaIds } = mediaAdapter.getSelectors<RootState>((state) => state.media);
export const { selectTotal: selectMediaTotal } = mediaAdapter.getSelectors<RootState>((state) => state.media);
// Custom Selecctors
export const selectMediaDifference = createSelector(
  [
    selectMediaIds,
    (state, media_ids) => media_ids
  ],
  // Output venue ids that are not downloaded (`selectVenueIds, venueIds)` as args
  (ids, media_ids) => (_.difference(media_ids, ids))
);
export const selectMediaSubset = createSelector(
  [
    selectAllMedia,
    (state, media_ids) => media_ids
  ],
  // Output selector gets (`selectAllVenues, venueIds)` as args
  (media, media_ids) => (media.filter(item => media_ids.indexOf(item.id) !== -1))
);

export const selectedMedia = (state: RootState) => state.media;

// And then use the selectors to retrieve values
// const venuesBooks = venuesSelectors.selectAll(store.getState());

export const { setSuccess, setError, setRequestMediaIds, mediaAdded, upsertMedia, cacheMediaAfterQuery } = mediaSlice.actions;

export default mediaSlice.reducer;
