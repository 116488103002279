import { createSlice, 
        // createEntityAdapter, 
        // PayloadAction, 
        // createSelector 
        } from '@reduxjs/toolkit';
import type { RootState /* ,store, */ } from '../../../../redux/store';
// import _ from 'lodash';

export interface chip {
    key: number;
    active: boolean;
    deletable: boolean;
    logic: boolean;
    tag: string; // tag
    subTag: string; // subTag
};

export interface chips{
    chip: chip[];
    status: string;
};

const initialState: chips = {
    chip: [
        {key: 0, active: true, deletable: false, logic: true, tag: 'VIBE', subTag: ''},
        {key: 1, active: true, deletable: false, logic: true, tag: 'CUISINE', subTag: ''},
        {key: 2, active: true, deletable: false, logic: true, tag: 'DISH', subTag: ''},
        {key: 3, active: true, deletable: false, logic: true, tag: 'DRINK', subTag: ''},
        ],
    status: 'idle'
  };

export const chipSearchSlice = createSlice({
    name: 'chipSearch',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: { 
    }
  })

export const selectedChipSearch = (state: RootState) => state.chipSearch;

export default chipSearchSlice.reducer;
