// @mui
import { Box, BoxProps } from '@mui/material';
// import { Outlet } from 'react-router';
// // hooks
// import useResponsive from '../../hooks/useResponsive';
// // config
// import { HEADER, NAV } from '../../config-global';
// components
// import { useSettingsContext } from '../../components/settings';

// ----------------------------------------------------------------------
//  This is the file where we are jogoing to adjust the size for mobile I think.

export default function Main({ children, sx, ...other }: BoxProps) {
  // const { themeLayout } = useSettingsContext();

  return (

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
  );
}
