import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../../redux/store';
import { Media } from '../media/mediaSlice'

export interface gridState {
  tileCount: number;
  data: Media[];
  options: any[];
  status: 'idle' | 'loading' | 'failed';
};

const initialState: gridState = {
  tileCount: 0,
  data: [],
  options: [],
  status: 'idle'
};

export const gridSlice = createSlice({
  name: 'grid',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateGridData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.tileCount = state.data.length;
    },
    hidetilesAfterFilter: (state) => { },
    showTileAfterFilter: (state) => { },
    addTilesAfterQuery: (state) => { },
    removeTilesAfterQuery: (state) => { },
    cacheTilesAfterQuery: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  }
});

export const selectedGrid = (state: RootState) => state.grid;

export const { updateGridData, hidetilesAfterFilter, showTileAfterFilter, addTilesAfterQuery, removeTilesAfterQuery, cacheTilesAfterQuery} = gridSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectedGrid = (state: RootState) => state.grid;

export default gridSlice.reducer;
