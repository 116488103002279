import { combineReducers } from 'redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
// import rootReducer, { rootPersistConfig } from './rootReducer';
// import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// FYE Slices
import gridReducer from '../pages/fyecleint/features/grid/gridSlice';
import mapReducer from '../pages/fyecleint/features/map/mapSlice';
import venuesReducer from '../pages/fyecleint/features/venues/venuesSlice';
import mediaReducer from '../pages/fyecleint/features/media/mediaSlice';
import mediaFiltersReducer from '../pages/fyecleint/features/media/mediaFiltersSlice';
import chipSearchReducer from "../pages/fyecleint/features/chipsSearch/chipSearchSlice"

// User
import { usersApi } from './api/usersApi';
import  usersReducer  from './slices/usersSlice'
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
// RTK APIs
import { mapApi } from '../pages/fyecleint/features/map/mapApi';
import { venuesApi } from '../pages/fyecleint/features/venues/venuesApi';
import { mediaApi } from '../pages/fyecleint/features/media/mediaApi';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

// export type RootState = ReturnType<typeof rootReducer>;

// export type AppDispatch = typeof store.dispatch;

const rootReducer = combineReducers({
  users: usersReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  venues: venuesReducer,
  media: mediaReducer,
  mediaFilters: mediaFiltersReducer,
  grid: gridReducer,
  map: mapReducer,
  chipSearch: chipSearchReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [mapApi.reducerPath]: mapApi.reducer,
  [venuesApi.reducerPath]: venuesApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
});


const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => 
    // getDefaultMiddleware().concat(mediaUserApi.middleware);
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
    .concat(usersApi.middleware)
    .concat(mapApi.middleware)
    .concat(venuesApi.middleware)
    .concat(mediaApi.middleware)
  ,
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

const useDispatch = () => useAppDispatch<AppDispatch>();



// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;

  export { store, persistor, dispatch, useSelector, useDispatch };