import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import GeoJSON from 'geojson'
import type { RootState } from '../../../../redux/store'

const debug = false;


const initLng = 0;
const initLat = 0;
export interface MapState {
  mapStyle: string;
  viewState: {
    latitude: number,
    longitude: number,
    zoom: number
  };
  status: 'idle' | 'intializing' | 'loading' | 'failed';
  mapBBox: number[][];
  venueCount: number;
  venues: any;
  venueIds: number[];
  mediaIds: number[];
  data: any;
  myLocation: [number, number];
}

const initialState = {
    mapStyle: 'mapbox://styles/mapbox/streets-v11',
    viewState: {
      latitude: 30.2672,
      longitude: -97.7431,
      zoom: 15
    },
  status: 'idle',
  mapBBox: [[],[]],
  venueCount: 0,
  venues: [],
  venueIds: [],
  mediaIds: [],
  data: [],
  myLocation: [initLng, initLat],
} as MapState;

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setViewState: (state, action: PayloadAction<any>) => {
      state.viewState = action.payload;
    },
    setBBoxState: (state,  action: PayloadAction<any>) => {
      state.mapBBox = action.payload;
    },
    setMyLocation: (state, action: PayloadAction<any>) => {
      if (debug) console.log('before setLocation', current(state))
      state.myLocation = action.payload;
      if (debug) console.log('after setLocation', current(state))
    },
    updateVenueData: (state, action: PayloadAction<any>) => {
      if (debug) console.log('before', current(state));
      state.venues = action.payload;
      // state.venues = _.unionBy(action.payload, state.venues, 'id');
      state.venueCount = state.venues.length;
      // state.data = GeoJSON.parse(state.venues, { Point: ['longitude', 'latitude'] });
      // @ts-ignore
      state.data = GeoJSON.parse(state.venues, { Point: ['latitude', 'longitude'] });
      // state.envelope = turf.envelope(state.data);
      if (debug) console.log('Total Venues Cached: ', state.venueCount);
      if (debug) console.log('after', current(state));
    },
    setResults: (state, action: PayloadAction<{venue_ids: number[], media_ids: number[]}>) => {
      // This sets the current venneIds and mediaIds that should be displayed on the map
      // I'm not sure if I need this in the the redux state yet
      // console.log(action.payload);
      state.venueIds = action.payload.venue_ids;
      state.mediaIds = action.payload.media_ids;
    },
    setVenueIds: (state, action: PayloadAction<number[]>) => {
      // console.log(action.payload);
      state.venueIds = action.payload;
    },
    setMediaIds: (state, action: PayloadAction<number[]>) => {
      // console.log(action.payload);
      state.mediaIds = action.payload;
    },
  },
})

export const { setViewState, setBBoxState, setMyLocation, updateVenueData, setResults, setVenueIds, setMediaIds } = mapSlice.actions;

export const selectedMap = (state: RootState) => state.map;

export default mapSlice.reducer;
