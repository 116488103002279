import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
// import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import EventIcon from '@mui/icons-material/Event';

interface ChipData {
  key: number;
  label: string;
}

const List = styled('ul')`
list-style: none;
overflow-x: auto;
`;

const ListItem = styled('li')`
  display: inline-block;
  padding: 0px 4px;
`;

// (({ theme }) => ({
//   margin: theme.spacing(0.5),
// }));

export default function ChipsArray() {
  const [chipData, setChipData] = React.useState<readonly ChipData[]>([
    { key: 0, label: 'VIBE' },
    { key: 1, label: 'CUISINE' },
    { key: 3, label: 'DRINK' },
    { key: 4, label: 'EVENT' },
    { key: 5, label: 'Chicken' },
    { key: 6, label: 'Shrimp' },
    { key: 7, label: 'Burgers' },
    { key: 8, label: 'Fries' },
  ]);
  // this part I will be able to use redux to inject data

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };
    // how do I get rid of paper
  return (
    <List>
      {chipData.map((data) => {
        let icon;

        if (data.label === 'VIBE') {
          icon = <TagFacesIcon />;
        }

        if (data.label === 'CUISINE') {
          icon = <FastfoodIcon />;
        }

        if (data.label === 'DRINK') {
          icon = <LocalBarIcon />;
        }

        if (data.label === 'EVENT') {
          icon = <EventIcon />;
        }

        return (
          <ListItem key={data.key}>
            <Chip  sx={{ borderRadius: 1.5 }}
              color='success'
              icon={icon}
              label={data.label}
              onDelete={data.label === ('VIBE' || 'CUISINE' || 'DRINK' || 'EVENT') ? undefined : handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
